// extracted by mini-css-extract-plugin
export var postlist = "postlist-module--postlist--Hk9eb";
export var item = "postlist-module--item--3ouv3";
export var postlist__item_wrapper = "postlist-module--postlist__item_wrapper--VYJCR";
export var item__info__title = "postlist-module--item__info__title--ELVaC";
export var item__info = "postlist-module--item__info--0Mcfu";
export var item__info__description = "postlist-module--item__info__description--eDg+U";
export var item__info__date = "postlist-module--item__info__date--+dkwK";
export var item__info_compact = "postlist-module--item__info_compact--yGlCh";
export var item__info_compact__title = "postlist-module--item__info_compact__title--2-Wkd";
export var item__info_compact__date = "postlist-module--item__info_compact__date--+O4ts";
export var item__image = "postlist-module--item__image--sP8ef";